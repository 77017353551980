import React from "react"

export default ({ white = false, grey = false, violet = false, translateY: y = "-1px" }) => (
  <img
    src={white ? require("../i/mat-white.svg") : grey ? require("../i/mat-grey.svg") : violet ? require("../i/mat-violet.svg") : require("../i/mat.svg")}
    height={"18"}
    alt={"MAT"}
    style={{ transform: `translateY(${y})` }}
  />
)
