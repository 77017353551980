import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import ExperienceMixanalogCTA from "../components/experience-cta"
import LocalFluid from "../components/fluid"
import MAT from "../components/mat"
import ImageMeta from "../components/ImageMeta"

const Price = ({ matPerMinute }) => (
  <span>
    {matPerMinute} <MAT grey /> per minute - start with 300 <MAT grey /> for signing up!
  </span>
)

// const AdditionalProductImage = ({ src }) => (
//   <a href={"#"} className="img_link">
//     <LocalFluid src={src} className={"mt-10 radius10"} />
//   </a>
// )

const Intro = ({ intro }) => (
  <section className="content_9 bg-light pb-95 pt-50">
    <div className="container px-xl-0">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-11 f-11 text-adaptive" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          {documentToReactComponents(intro.json)}
        </div>
      </div>
    </div>
  </section>
)

const Usage = ({ usage, title, guiHeroImage, usageBgImage }) => (
  <BackgroundImage Tag="section" className="pt-100 pb-100" fluid={usageBgImage.childImageSharp.fluid}>
    <div className="container px-xl-0">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-11 col-md-12">
          <div className="pt-55 pb-50 bg-light radius10 text-left text-adaptive inner shadow-sm">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-11 col-md-12">
                <h2 className="mb-15 small aos-init aos-animate" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                  Where to use {title}?
                </h2>
                <div className="f-17 color-heading text-adaptive aos-init aos-animate" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
                  {documentToReactComponents(usage.json)}
                  {!guiHeroImage ? undefined : <LocalFluid src={guiHeroImage} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BackgroundImage>
)

const TutorialVideo = ({ videoTutorialUrl, title }) => {
  const videoMatch = videoTutorialUrl.match(/\?v=(.*)$/)
  const videoId = videoMatch ? videoMatch[1] : undefined
  /*
   <img srcSet="i/content_24_video_poster@2x.jpg 2x" src="i/content_24_video_poster.jpg" className="img-fluid" alt="" />
                <div className="play red">
                  <i className="fas fa-play" />
                </div>

   <div className="mt-20 ml-20 absolute bg-light f-14 lh-30 color-red text-uppercase semibold sp-20 px-10 radius10 tip">{title} Tutorial</div>
   */

  return videoId ? (
    <section className="content_24 bg-light pt-80 pb-95">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col rounded">
            <h2 className="mb-50 small aos-init aos-animate" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
              {title} Video Tutorial
            </h2>
            <div
              className="video radius10"
              style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                paddingTop: 25,
                height: 0,
                boxShadow: "0px 1px 2px black",
              }}
            >
              <iframe
                title={"video"}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
                src={`https://www.youtube.com/embed/${videoId}`}
              />
            </div>
            <span className={"color-heading pt-10 d-inline-block"}>
              If you liked the video, please consider{" "}
              <a href={"https://youtube.com/c/mixanalogcloud?sub_confirmation=1"} target={"_blank"} rel={"noopener noreferrer"}>
                subscribing
              </a>{" "}
              to our YouTube channel where you can find more video tutorials.
            </span>
          </div>
        </div>
      </div>
    </section>
  ) : undefined
}

const ArrowNavigation = ({ previous, next }) => (
  <React.Fragment>
    <Link className="arrow-container left" to={`/products/${previous}`} />
    <Link className="arrow-container right" to={`/products/${next}`} />
  </React.Fragment>
)

const ProductPage = ({ pageContext: { previous, next }, data: { product, usageBgImage } }) => {
  const { /* additionalImages */ summary, title, intro, usage, heroImage, horizontalHeroImage, matPerMinute, freeTier, category, guiHeroImage, videoTutorialUrl } = product

  return (
    <LayoutPage>
      <SEO title={title} meta={ImageMeta} />
      <ArrowNavigation previous={previous} next={next} />
      <section className="ecommerce_6 bg-light">
        <div className="container px-xl-0">
          <div className="row justify-content-between align-items-stretch">
            <div className="col-xl-6 col-lg-5 col-md-4 bg-light pt-100" data-aos-duration="600" data-aos="fade-down" data-aos-delay="900">
              {heroImage ? <LocalFluid src={horizontalHeroImage || heroImage} /> : undefined}
            </div>
            <div className="col-xl-5 col-lg-6 col-md-7 pt-100 pb-100 inner">
              <div className="f-14 text-uppercase semibold sp-20 subtitle" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                {(category || []).join(", ")}
              </div>
              <h2 className="mt-10 mb-20 small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
                {title}
              </h2>
              <div className="color-heading text-adaptive" data-aos-duration="600" data-aos="fade-down" data-aos-delay="600">
                {!summary ? undefined : documentToReactComponents(summary.json)}
                <div className="mt-75 d-flex flex-wrap align-items-center buttons" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                  <a href={"https://app.mixanalog.com"} className=" mr-15 btn action-2 px-xl-4">
                    Book {title}
                  </a>
                  <span className="medium">{freeTier ? "FREE up to 30 minutes every day" : <Price matPerMinute={matPerMinute} />}</span>
                </div>
                {/* TODO: what is this below */}
                {/* <div className="mt-30 d-flex flex-wrap images" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                  {(additionalImages || []).map((img, i) => (
                    <AdditionalProductImage key={i} src={img} />
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {!intro ? undefined : <Intro intro={intro} />}
      {!usage ? undefined : <Usage usage={usage} title={title} guiHeroImage={guiHeroImage} usageBgImage={usageBgImage} />}
      {!videoTutorialUrl ? undefined : <TutorialVideo videoTutorialUrl={videoTutorialUrl} title={title} />}
      <ExperienceMixanalogCTA toppad />
    </LayoutPage>
  )
}

export default ProductPage

export const query = graphql`
  query($engineId: String!) {
    usageBgImage: file(relativePath: { eq: "working-in-studio.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 4096) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    product: contentfulProduct(engineId: { eq: $engineId }) {
      summary {
        json
      }
      intro {
        json
      }
      usage {
        json
      }
      engineId
      title
      freeTier
      matPerMinute
      category
      videoTutorialUrl
      additionalImages {
        localFile {
          childImageSharp {
            fluid(maxWidth: 360) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      guiHeroImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 4096) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      horizontalHeroImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 4096) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      heroImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 4096) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
